<template>

    <div class="box" style="margin: 0 auto">

        <div style="position: absolute;background-color: #ffd700;padding: 5px 10px;border-radius: 50px;color: #0c0e0e">
            <span>골드:</span> {{$store.state.userInfo.gcoupon}}
        </div>
        <img alt="" src="../../assets/images/bg/rullet/rullet_bg_g.png" style="width: 100%"/>
        <LuckyWheel
                class="luck-draw"
                ref="LuckyWheel"
                :blocks="blocks"
                :prizes="prizes"
                :buttons="buttons"
                :default-style="defaultStyle"
                :default-config="defaultConfig"
                @start="startCallBack"
                @end="endCallBack"
        />


    </div>
</template>

<script>
    import {getRulletSetting, runRullet} from "../../network/leisureRequest";

    export default {
        name: "GoldRullet",
        data() {
            return {
                prizes: [],
                buttons: [{
                    radius: '40px',
                    imgs: [{src: require('../../assets/images/icon/rullet_start_btn.png'), width: '100%', top: '-100%'}]
                }],
                blocks: [
                    {padding: '5px', background: '#ffd700'},
                ],
                defaultStyle: {
                    fontColor: '#303133',
                    fontSize: '13px',
                },
                defaultConfig: {
                    gutter: '1px',
                },
                data: []
            }
        },

        methods: {
            getPrizesList() {
                const prizes = []
                this.data.forEach((item, index) => {
                    prizes.push({
                        name: item.name,
                        background: item.color,
                        fonts: [{text: item.name, top: '10%'}],
                        imgs: [{src: item.img, width: '30%', top: '35%'}],
                    })
                })
                this.prizes = prizes
            },
            startCallBack() {
                if (this.$store.state.userInfo.gcoupon <= 0) {
                    this.$swal({
                        title: '쿠폰 획득후 사용가능합니다',
                        type: 'warning',
                        showCancelButton: false,
                        showConfirmButton: true
                    })
                    return false;
                }
                this.$refs.LuckyWheel.play()
                setTimeout(() => {
                    runRullet('g').then(res=>{
                        if(res.data.success){
                            let n = res.data.data;
                            this.$refs.LuckyWheel.stop(n-1)
                        } else {
                            this.$swal({
                                title: res.data.msg,
                                type: 'error',
                                showCancelButton: false,
                                showConfirmButton: true
                            })
                        }
                    })

                }, 500)
            },
            endCallBack(prize) {
                this.$swal({
                    title: `축하합니다. ${prize.name} 포인트 획득 하였습니다 `,
                    type: 'success',
                    showCancelButton: false,
                    showConfirmButton: true
                })
                this.$store.dispatch('actionUserInfo')
            },
        },
        mounted() {
            getRulletSetting('g').then(res => {
                let response = res.data.data
                this.data = [
                    {
                        name: response["1"].name,
                        img: require('../../assets/images/icon/rullet_g_icon.png'),
                        color: '#d7d7d7'
                    },
                    {
                        name: response["2"].name,
                        img: require('../../assets/images/icon/rullet_g_icon.png'),
                        color: '#fef43e',
                    },
                    {
                        name: response["3"].name,
                        img: require('../../assets/images/icon/rullet_g_icon.png'),
                        color: '#ef7683'
                    },
                    {
                        name: response["4"].name,
                        img: require('../../assets/images/icon/rullet_g_icon.png'),
                        color: '#d7d7d7'
                    },
                    {
                        name: response["5"].name,
                        img: require('../../assets/images/icon/rullet_g_icon.png'),
                        color: '#fef43e'
                    },
                    {
                        name: response["6"].name,
                        img: require('../../assets/images/icon/rullet_g_icon.png'),
                        color: '#ef7683'
                    },
                    {
                        name: response["7"].name,
                        img: require('../../assets/images/icon/rullet_g_icon.png'),
                        color: '#d7d7d7'
                    },
                    {
                        name: response["8"].name,
                        img: require('../../assets/images/icon/rullet_g_icon.png'),
                        color: '#fef43e'
                    },
                    {
                        name: response["9"].name,
                        img: require('../../assets/images/icon/rullet_g_icon.png'),
                        color: '#fef43e'
                    },
                    {
                        name: response["10"].name,
                        img: require('../../assets/images/icon/rullet_g_icon.png'),
                        color: '#fef43e'
                    },
                ]
                this.getPrizesList()
            })
        }
    }
</script>

<style scoped>

</style>