import { render, staticRenderFns } from "./SilverRullet.vue?vue&type=template&id=1b52780c&scoped=true&"
import script from "./SilverRullet.vue?vue&type=script&lang=js&"
export * from "./SilverRullet.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b52780c",
  null
  
)

export default component.exports